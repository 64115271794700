import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import {HomePageServiceService} from '../../home-page-service.service';
import {apiServer} from '../../../../shared/constants/host';

@Component({
  selector: 'app-service-eco',
  templateUrl: './service-eco.component.html',
  styleUrls: ['./service-eco.component.scss']
})
export class ServiceEcoComponent implements OnInit {
  itemsPerSlide = 3;

  slides = [
    {
      "id": 1,
      "title": "Gửi hàng đi mỹ",
      "short_description": "EcoExpress vận chuyển rất nhiều mặt hàng đi Mỹ như:  Hàng cồng kềnh: gửi nệm đi Mỹ, bàn ghế, tủ giường, nội thất gỗ đi Mỹ….",
      "url": "services/go-to-america",
      "published_at": "2021-02-07T07:36:59.000Z",
      "created_at": "2021-02-07T07:25:00.000Z",
      "updated_at": "2021-04-08T14:58:15.000Z",
      "thumnail": {
        "id": 23,
        "name": "a7ec60926cad9ef3c7bc.jpg",
        "alternativeText": "",
        "caption": "",
        "width": 2465,
        "height": 1914,
        "hash": "a7ec60926cad9ef3c7bc_7a10610017",
        "ext": ".jpg",
        "mime": "image/jpeg",
        "size": 565.57,
        "url": "../assets/imgs/guihangdimy.jpg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "created_at": "2021-04-08T14:55:44.000Z",
        "updated_at": "2021-04-08T14:58:11.000Z"
      }
    },
    {
      "id": 2,
      "title": "Gửi hàng đi châu Á",
      "short_description": "Gửi hàng đi Châu Á (Hàn Quốc, Trung Quốc, Nhật Bản, Đài Loan,..) Eco Express  là đại lý chính thức của các hãng vận chuyển lớn...",
      "url": "services/go-to-asia",
      "published_at": "2021-02-07T07:36:51.000Z",
      "created_at": "2021-02-07T07:30:45.000Z",
      "updated_at": "2021-04-08T14:59:03.000Z",
      "thumnail": {
        "id": 20,
        "name": "244847d24cedbeb3e7fc.jpg",
        "alternativeText": "",
        "caption": "",
        "width": 1501,
        "height": 1546,
        "hash": "244847d24cedbeb3e7fc_05b7c45aea",
        "ext": ".jpg",
        "mime": "image/jpeg",
        "size": 428.93,
        "url": "../assets/imgs/chauau.jpg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "created_at": "2021-04-08T14:55:44.000Z",
        "updated_at": "2021-04-08T14:59:00.000Z"
      }
    },
    {
      "id": 3,
      "title": "Gửi hàng đi châu âu",
      "short_description": "Gửi hàng đi Châu Âu (Hà Lan, Na Uy, Anh, Pháp, Đức,..), Eco express  là đại lý chính thức của các hãng vận chuyển lớn...",
      "url": "services/go-to-europe",
      "published_at": "2021-02-07T07:36:55.000Z",
      "created_at": "2021-02-07T07:36:03.000Z",
      "updated_at": "2021-04-08T15:00:53.000Z",
      "thumnail": {
        "id": 16,
        "name": "6db1802e8b11794f2000.jpg",
        "alternativeText": "",
        "caption": "",
        "width": 1018,
        "height": 627,
        "hash": "6db1802e8b11794f2000_2ecd385e6e",
        "ext": ".jpg",
        "mime": "image/jpeg",
        "size": 73.12,
        "url": "../assets/imgs/chaua.jpg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "created_at": "2021-04-08T14:55:43.000Z",
        "updated_at": "2021-04-08T14:55:43.000Z"
      }
    },
    {
      "id": 4,
      "title": "Gửi chứng từ đi các nước",
      "short_description": "Dịch vụ chuyển thư, chứng từ, và các loại giấy tờ quan trọng….đi Mỹ, Pháp, Anh, Đức, Canada, Thái Lan, Singapore, Nhật Bản….",
      "url": "services/send-paper-to-other-country",
      "published_at": "2021-02-07T07:39:31.000Z",
      "created_at": "2021-02-07T07:38:49.000Z",
      "updated_at": "2021-04-08T15:00:05.000Z",
      "thumnail": {
        "id": 17,
        "name": "da93fb0af035026b5b24.jpg",
        "alternativeText": "",
        "caption": "",
        "width": 2057,
        "height": 1662,
        "hash": "da93fb0af035026b5b24_4f346240ef",
        "ext": ".jpg",
        "mime": "image/jpeg",
        "size": 458.93,
        "url": "../assets/imgs/dicacnuoc.jpg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "created_at": "2021-04-08T14:55:43.000Z",
        "updated_at": "2021-04-08T15:00:02.000Z"
      }
    },
    {
      "id": 5,
      "title": "Gửi hàng đi các nước",
      "short_description": "Với kinh nghiệm lâu năm trong lĩnh vực vận chuyển hàng hóa quốc tế, Đội ngũ nhân viên chuyên nghiệp...",
      "url": "services/go-to-other-country",
      "published_at": "2021-02-07T07:40:13.000Z",
      "created_at": "2021-02-07T07:40:12.000Z",
      "updated_at": "2021-04-08T15:00:31.000Z",
      "thumnail": {
        "id": 15,
        "name": "92024d62415db303ea4c.jpg",
        "alternativeText": "",
        "caption": "",
        "width": 2568,
        "height": 1926,
        "hash": "92024d62415db303ea4c_ccbcc96e4b",
        "ext": ".jpg",
        "mime": "image/jpeg",
        "size": 535.44,
        "url": "../assets/imgs/guihang.jpg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "created_at": "2021-04-08T14:53:59.000Z",
        "updated_at": "2021-04-08T14:53:59.000Z"
      }
    }
  ];
  serverUrl = apiServer;


  deviceInfo = null;
  trendSerices;

  constructor(private deviceService: DeviceDetectorService, private homePageServiceService: HomePageServiceService) {
    this.epicFunction();
    this.getTrendServices();
    // this.getSlider();
  }

  ngOnInit(): void {
  }

  // tslint:disable-next-line:typedef
  getTrendServices() {
    this.homePageServiceService.getShortServices().subscribe(data => {
      this.trendSerices = data[0];
    });
  }
  // tslint:disable-next-line:typedef
  // getSlider() {
  //   this.homePageServiceService.getSlider().subscribe(data => {
  //     this.slides = data;
  //   });
  // }

  // tslint:disable-next-line:typedef
  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    if (isMobile) {
      this.itemsPerSlide = 1;
    }
  }
}

