import { Component, OnInit } from '@angular/core';
import { apiServer } from 'src/app/shared/constants/host';
import { IBanner } from 'src/app/shared/models/iBanner';
import { OurServicesService } from '../../our-services.service';


@Component({
  selector: 'app-sendPaperToOtherCountry',
  templateUrl: './sendPaperToOtherCountry.component.html',
  styleUrls: ['./sendPaperToOtherCountry.component.scss']
})
export class SendPaperToOtherCountryComponent implements OnInit {


  banner: IBanner;
  serverUrl = apiServer;

  constructor(private ourServicesService: OurServicesService) { }

  ngOnInit(): void {
    this.ourServicesService.getBanner().subscribe(
      res => {
        if (res && res[0])
          this.banner = res[0];
      },
      err => {
        console.log(err);
      });
  }
}
