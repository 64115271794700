<div class="contact">
  <!-- <div class="facebook">
    <button class="link">Chat Facebook</button>
  </div> -->
  <div class="hotline">
    <a class="phone" href="tel:+84902354886"><button class="link">Hotline: 0902354886</button></a>
  </div>
  <!-- <div class="zalo-chat-widget" data-oaid="2432114881437377893" data-welcome-message="Rất vui khi được hỗ trợ bạn!"
    data-autopopup="0" data-width="500" data-height="500"></div> -->
    <div class="zalo">
      <a href="https://zalo.me/0902354886" target="_blank">
        <img src="../../../../assets/imgs/zalo.png" alt="" srcset="" style="width: 60px;">
      </a>
    </div>
  <div class="fb-customerchat" attribution="setup_tool" page_id="112128604247811">
  </div>
</div>
