import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GoToAmericaComponent } from './pages/goToAmerica/goToAmerica.component';
import { GotoAsiaComponent } from './pages/gotoAsia/gotoAsia.component';
import { GoToEuropeComponent } from './pages/goToEurope/goToEurope.component';
import { GoToOtherCountryComponent } from './pages/goToOtherCountry/goToOtherCountry.component';
import { OtherServicesComponent } from './pages/otherServices/otherServices.component';
import { SendPaperToOtherCountryComponent } from './pages/sendPaperToOtherCountry/sendPaperToOtherCountry.component';

const routes: Routes = [
    {
        path: 'go-to-america',
        component: GoToAmericaComponent,
    },
    {
        path: 'go-to-asia',
        component: GotoAsiaComponent,
    },
    {
        path: 'go-to-europe',
        component: GoToEuropeComponent,
    },
    {
        path: 'go-to-other-country',
        component: GoToOtherCountryComponent,
    },
    {
        path: 'send-paper-to-other-country',
        component: SendPaperToOtherCountryComponent,
    },
    {
        path: 'other-service',
        component: OtherServicesComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class OurServicesRoutingModule { }
