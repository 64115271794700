<app-default>
    <div class="banner-container container-fluid banner1">
        <img class="img" src="../../../../assets/imgs/plane.jpg" alt="first slide">
        <div class="img-background"></div>
        <div class="banner-title container">
            <div class="decription">Phụ phí </div>
            <div class="line"></div>
        </div>
    </div>
    <div class="fee-container container">
        <div class="row phu-phi">
            <div class="col-12 title"> Phụ phí nhiên liệu Và phụ phí khác </div>
            <div class="col-12 m-0">
                <div class="item-1 row">
                    <div class="group col-sm-12 col-md-10">
                        <div><img src="../../../../assets/imgs/phu-phi-1.jpg" class="img" alt=""></div>
                        <div class="decription">Phụ phí nhiên liệu Fedex </div>
                    </div>
                    <a class="button col-sm-12 col-md-2" href="https://www.fedex.com/vi-vn/shipping/surcharges.html">
                        XEM CHI TIẾT <i class="fas fa-expand-alt"></i></a>
                </div>
                <div class="item-2 row">
                    <div class="group col-sm-12 col-md-10">
                        <div><img src="../../../../assets/imgs/phu-phi-1.jpg" class="img" alt=""></div>
                        <div class="decription">Phụ phí khác Fedex </div>
                    </div>
                    <a class="button col-sm-12 col-md-2"
                        href="https://www.fedex.com/vi-vn/shipping/surcharges/other-surcharges.html"> XEM
                        CHI TIẾT <i class="fas fa-expand-alt"></i></a>
                </div>
                <div class="item-1 row">
                    <div class="group col-sm-12 col-md-10">
                        <div><img src="../../../../assets/imgs/phu-phi-2.jpg" class="img" alt=""></div>
                        <div class="decription">Phụ phí nhiên liệu DHL </div>
                    </div>
                    <a class="button col-sm-12 col-md-2"
                        href="http://www.dhl.com.vn/exp-vi/express/shipping/shipping_advice/express_fuel_surcharge.html">
                        XEM CHI TIẾT <i class="fas fa-expand-alt"></i></a>
                </div>
                <div class="item-2 row">
                    <div class="group col-sm-12 col-md-10">
                        <div><img src="../../../../assets/imgs/phu-phi-2.jpg" class="img" alt=""></div>
                        <div class="decription">Phụ phí khác DHL </div>
                    </div>
                    <a class="button col-sm-12 col-md-2"
                        href="http://www.dhl.com.vn/exp-vi/express/shipping/shipping_advice/surcharges.html">
                        XEM CHI TIẾT <i class="fas fa-expand-alt"></i></a>
                </div>
                <div class="item-2 row">
                  <div class="group col-sm-12 col-md-10">
                      <div><img src="../../../../assets/imgs/ups.png" class="img" alt=""></div>
                      <div class="decription">Phụ phí nguyên liệu UPS </div>
                  </div>
                  <a class="button col-sm-12 col-md-2"
                      href="https://www.ups.com/vn/vi/shipping/surcharges/fuel-surcharges.page">
                      XEM CHI TIẾT <i class="fas fa-expand-alt"></i></a>
              </div>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-md-6 col-sm-12 m-0">
                <div class="right-side">
                    <div class="header">
                        <span>Thông tin hỗ trợ </span>
                    </div>
                    <div class="content">
                        <ul>
                            <li>Địa chỉ: 74/1 Nguyễn Thái Sơn, Phường 3, Quận Gò Vấp, HCM</li>
                            <li>HOTLINE: 0902354886</li>
                            <!-- <li>Fax: </li> -->
                            <li>Email: info@ecoexpress.vn</li>
                            <!-- <li>Website: www.logasia-express.com</li> -->
                        </ul>
                        <!-- <img class="logo" src="../../../../assets/imgs/img-4.png" alt=""> -->
                    </div>
                </div>
            </div>
            <div class="col-md-6 row col-sm-12 m-0">
                <div class="left-side">
                    <img class="logo" src="../../../../assets/imgs/img-4.png" alt="">
                    <!-- <div class="header">
                        <span><i class="fas fa-envelope"></i> Yêu cầu xin báo giá
                        </span>
                    </div>
                    <div class="content">
                        <form (ngSubmit)="onSubmit()" [formGroup]="feeForm">
                            <div class="form-group">
                                <label for="hoten">Tên của bạn (bắt buộc)
                                </label>
                                <input type="text" class="form-control" id="hoten" name="hoten" formControlName="hoten">
                                <div *ngIf="feeForm.controls.hoten.errors?.required && submitted"
                                    class="alert alert-danger">
                                    Mục này là bắt buộc!
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="email">Địa chỉ Email (bắt buộc) </label>
                                <input type="text" class="form-control" id="email" name="email" formControlName="email"
                                    email="true">
                                <div *ngIf="feeForm.controls.email.errors?.required && submitted"
                                    class="alert alert-danger">
                                    Mục này là bắt buộc!
                                </div>
                                <div *ngIf="feeForm.controls.email.errors?.email && submitted"
                                    class="alert alert-danger">
                                    Email không hợp lệ!
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="dienThoai">Điện thoại (bắt buộc)
                                </label>
                                <input type="text" class="form-control" id="dienThoai" name="dienThoai"
                                    formControlName="dienThoai">
                                <div *ngIf="feeForm.controls.dienThoai.errors?.required && submitted"
                                    class="alert alert-danger">
                                    Mục này là bắt buộc!
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="noiNhanHang">Nơi nhận hàng (gửi từ)
                                </label>
                                <input type="text" class="form-control" id="noiNhanHang" name="noiNhanHang"
                                    formControlName="noiNhanHang">
                            </div>

                            <div class="form-group">
                                <label for="nơiGuiDen">Nơi muốn gửi đến (gửi tới)
                                </label>
                                <input type="text" class="form-control" id="nơiGuiDen" name="nơiGuiDen"
                                    formControlName="nơiGuiDen">
                            </div>

                            <div class="form-group">
                                <label for="kichThuoc">Kích thước
                                </label>
                                <input type="text" class="form-control" id="kichThuoc" name="kichThuoc"
                                    formControlName="kichThuoc">
                            </div>

                            <div class="form-group">
                                <label for="fname">Khối lượng
                                </label>
                                <input type="text" class="form-control" id="khoiLuong" name="khoiLuong"
                                    formControlName="khoiLuong">
                            </div>

                            <div class="form-group">
                                <label for="ghiChu">Ghi chú </label>
                                <textarea class="form-control" id="ghiChu" name="ghiChu" formControlName="ghiChu"
                                    cols="30" rows="5"></textarea>
                            </div>

                            <button type="submit" class="btn">Gữi đi</button>
                        </form>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</app-default>
