<app-default>
    <div class="banner-container container-fluid banner1">
        <img class="img" src="../../../../assets/imgs/container.jpg" alt="first slide">
        <div class="img-background"></div>
        <div class="banner-title container">
            <div class="decription">HỖ TRỢ KHÁCH HÀNG </div>
            <div class="line"></div>
        </div>
    </div>
    <div class="support-container container">
        <div class="row m-0">
            <div class="col-md-6 row col-sm-12 m-0">
                <div class="left-side">
                    <div class="header">
                        <span>CÔNG TY ECO EXPRESS </span>
                    </div>
                    <div class="content">
                        <ul>
                            <li>Địa chỉ: 74/1 Nguyễn Thái Sơn, Phường 3, Quận Gò Vấp, HCM</li>
                            <li>Điện thoại: 0902354886</li>
                            <!-- <li>Fax: </li> -->
                            <li>Email: info@ecoexpress.vn</li>
                            <li>Website: https://ecoexpress.vn</li>
                        </ul>
                        <!-- <img class="logo" src="../../../../assets/imgs/logo.png" alt=""> -->
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12 m-0">
                <div class="right-side">
                    <img class="logo" src="../../../../assets/imgs/logo.png" alt="">
                    <!-- <div class="header">
                        <span><i class="fas fa-envelope"></i> Gửi thông tin liên hệ
                        </span>
                    </div>
                    <div class="content">
                        <form (ngSubmit)="onSubmit()" [formGroup]="supportForm">
                            <div class="form-group">
                                <label for="fname">Tên của bạn (bắt buộc)
                                </label>
                                <input type="text" class="form-control" id="name" name="name" formControlName="name">
                                <div *ngIf="supportForm.controls.name.errors?.required && submitted"
                                    class="alert alert-danger">
                                    Mục này là bắt buộc!
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="email">Địa chỉ Email (bắt buộc) </label>
                                <input type="text" class="form-control" id="email" name="email" formControlName="email"
                                    email="true">
                                <div *ngIf="supportForm.controls.email.errors?.required && submitted"
                                    class="alert alert-danger">
                                    Mục này là bắt buộc!
                                </div>
                                <div *ngIf="supportForm.controls.email.errors?.email && submitted"
                                    class="alert alert-danger">
                                    Email không hợp lệ!
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="title">Tiêu đề:</label>
                                <input type="text" class="form-control" id="title" name="title" formControlName="title">
                            </div>

                            <div class="form-group">
                                <label for="message">Thông điệp</label>
                                <textarea class="form-control" id="message" name="message" formControlName="message"
                                    cols="30" rows="5"></textarea>
                            </div>

                            <button type="submit" class="btn">Gữi đi</button>
                        </form>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="row map">
            <div class="col-12 title"> Bản đồ đường đi </div>
            <div class="col-12 m-0">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3918.9467481886613!2d106.67727537561609!3d10.815387289335817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317528e1057212bf%3A0x15fa4e745ec9a3b2!2zNzQgTmd1eeG7hW4gVGjDoWkgU8ahbiwgUGjGsOG7nW5nIDMsIEfDsiBW4bqlcCwgVGjDoG5oIHBo4buRIEjhu5MgQ2jDrSBNaW5oLCBWaeG7h3QgTmFt!5e0!3m2!1svi!2s!4v1681281410906!5m2!1svi!2s" width="100%" height="250" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>
</app-default>
