import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShareModule } from 'src/app/shared/share.module';
import { AboutRoutingModule } from './about-routing.module';
import { AboutUsComponent } from './pages/about-us/about-us.component';

@NgModule({
  declarations: [AboutUsComponent],
  imports: [
    CommonModule,
    ShareModule,
    AboutRoutingModule,
  ]
})
export class AboutModule { }
