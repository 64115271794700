import { Component, OnInit } from '@angular/core';
import { IBanner } from 'src/app/shared/models/iBanner';
import { NewsService } from '../news.service';
import { HomePageServiceService } from '../../home/home-page-service.service';
import { apiServer } from '../../../shared/constants/host';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  serverUrl = apiServer;
  banner: IBanner;

  constructor(private newsService: NewsService) { }
  listNews;

  ngOnInit(): void {
    this.getNews();
    this.newsService.getBanner().subscribe(
      res => {
        if (res && res[0])
          this.banner = res[0];
      },
      err => {
        console.log(err);
      });
  }

  convertMonth(value: string = "2021-02-07T08:49:56.000Z") {
    let date = new Date(value);
    let resutl = date.toString().split(" ")[1]
    return resutl;
  }

  convertDays(value: string = "2021-02-07T08:49:56.000Z") {
    let date = new Date(value);
    let resutl = date.toString().split(" ")[2]
    return resutl;
  }

  getNews() {
    this.newsService.getNews().subscribe(data => {
      this.listNews = data;
    });
  }

  changePage(value) {
    console.log(value);
  }
}
