<nav ngxNavbarDynamicExpand #curtain class="navbar navbar-expand-lg navbar-light bg-light sticky-top header">
  <div class="container">
    <a [ngClass]="{'navbar-brand': !setHeight,'set-height': setHeight  }" href="#">
      <img class="w-100" src="../../../../assets/imgs/logo.png" alt="" srcset="">
    </a>
    <button class="navbar-toggler" type="button" (click)="collapse.toggle()" aria-controls="main-nav"
      aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon d-flex align-items-center justify-content-center">
      </span>
    </button>
    <ngx-navbar-collapse id="main-nav" #collapse="ngxNavbarCollapse">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown">
          <a class="nav-link dropbtn mt1px" href="#">TRANG CHỦ</a>
        </li>
        <li class="nav-item dropdown">
          <div class="dropbtn">DỊCH VỤ <i class="fas fa-caret-down"></i></div>
          <div class="nav-link dropdown-one">
            <div class="dItem"> <a href="/services/go-to-america">Gửi hàng đi Mỹ</a></div>
            <div class="dItem"> <a href="/services/go-to-asia">Gửi hàng đi châu Á</a></div>
            <div class="dItem"> <a href="/services/go-to-europe">Gửi hàng đi châu Âu</a></div>
            <div class="dItem"> <a href="/services/go-to-other-country">Gửi hàng đi các nước</a></div>
            <div class="dItem"> <a href="/services/send-paper-to-other-country">Gửi chứng từ đi nước ngoài</a></div>
          </div>
        </li>
        <li class="nav-item dropdown">
          <div class="nav-link dropbtn">GIỚI THIỆU <i class="fas fa-caret-down"></i></div>
          <div class="dropdown-one">
            <div class="dItem"><a href="/about-us">Về chúng tôi</a></div>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropbtn mt1px" href="/news">TIN TỨC</a>
        </li>
        <li class="nav-item dropdown">
          <div class="dropbtn">QUY ĐỊNH <i class="fas fa-caret-down"></i></div>
          <div class="dropdown-one">
            <div class="dItem"><a href="/policy/receive">Chính sách nhận hàng</a></div>
            <div class="dItem"><a href="/policy/payment">Chính sách thanh toán</a></div>
            <div class="dItem"><a href="/policy/compensation">Chính sách đền bù</a></div>
            <div class="dItem"><a href="/policy/delivery">Phương thức giao – nhận hàng</a></div>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropbtn mt1px" href="/fee">PHỤ PHÍ</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropbtn mt1px" href="/support">LIÊN HỆ</a>
        </li>
      </ul>
    </ngx-navbar-collapse>
  </div>
</nav>