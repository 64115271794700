import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsService } from '../../news.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, OnDestroy {

  listNews;
  news;
  html: string = " ";
  private sub: any;

  constructor(private route: ActivatedRoute, private newsService: NewsService) { }

  ngOnInit() {
    this.getNews();
    this.sub = this.route.params.subscribe(params => {
      if (params) {
        this.newsService.getNewsDetails(params['id']).subscribe(
          res => {
            if (res) {
              this.news = res;
            }
            console.log(res);
          },
          err => {
            console.log(err);
          });
      }
    });
  }

  convertMonth(value: string = "2021-02-07T08:49:56.000Z") {
    let date = new Date(value);
    let resutl = date.toString().split(" ")[1]
    return resutl;
  }

  convertDays(value: string = "2021-02-07T08:49:56.000Z") {
    let date = new Date(value);
    let resutl = date.toString().split(" ")[2]
    return resutl;
  }

  getNews() {
    this.newsService.getNews().subscribe(data => {
      this.listNews = data;
    });
  }

  setSlideHtml(html) {
    this.html = html;
    console.log(html);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
