import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeeComponent } from './pages/fee.component';
import { ShareModule } from 'src/app/shared/share.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [FeeComponent],
  imports: [
    CommonModule,
    ShareModule,
    FormsModule
  ]
})
export class FeeModule { }
