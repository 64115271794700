import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DefaultComponent } from './layout/default/default.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { TitleComponentComponent } from './component/title-component/title-component.component';
import { ButtonComponentComponent } from './component/button-component/button-component.component';
import { NgxNavbarModule } from 'ngx-bootstrap-navbar';
import { BannerComponent } from './component/banner/banner.component';
import { SharedService } from './shared.service';
import { ContactComponent } from './component/contact/contact.component';

@NgModule({
  declarations: [
    DefaultComponent,
    HeaderComponent,
    FooterComponent,
    TitleComponentComponent,
    ButtonComponentComponent,
    BannerComponent,
    ContactComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxNavbarModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DefaultComponent,
    TitleComponentComponent,
    ButtonComponentComponent,
    BannerComponent,
    ContactComponent
  ],
  providers: [SharedService],
  bootstrap: []
})
export class ShareModule { }
