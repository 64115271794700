import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiServer } from 'src/app/shared/constants/host';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  serverUrl = apiServer;

  constructor(private http: HttpClient) { }

  getBanner() {
    return this.http.get(`${this.serverUrl}/banners`);
  }

  getAboutUs() {
    return this.http.get(`${this.serverUrl}/about-uses`);
  }

  getNewsDetails(id) {
    // return this.http.get(`${this.serverUrl}/events/${id}`, { responseType: 'text' });
    return this.http.get(`${this.serverUrl}/events/${id}`);
  }

  getNews = () => {
    return this.http.get(`${this.serverUrl}/events`);
  }
}
