import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeModule } from './home/home.module';
import { NewsModule } from './news/news.module';
import { OurServicesModule } from './our-services/our-services.module';
import { Page404Component } from './page404/page404/page404.component';
import { AboutModule } from './about-us/about.module';
import { PolicyModule } from './policy/policy.module';
import { SupportModule } from './support/support.module';
import { FeeModule } from './fee/fee.module';

@NgModule({
  imports: [
    CommonModule,
    HomeModule,
    NewsModule,
    OurServicesModule,
    AboutModule,
    PolicyModule,
    SupportModule,
    FeeModule
  ],
  exports: [Page404Component],
  providers: [],
  bootstrap: [],
  declarations: [Page404Component]
})
export class ModulesModule { }
