import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ShareModule } from 'src/app/shared/share.module';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './pages/home/home.component';
import { IntroComponent } from './components/intro/intro.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ServiceEcoComponent } from './components/service-eco/service-eco.component';
import { InfoComponent } from './components/info/info.component';

@NgModule({
  imports: [
    CarouselModule.forRoot(),
    ShareModule,
    HomeRoutingModule,
    CommonModule,
  ],
  declarations: [
    HomeComponent,
    IntroComponent,
    ServiceEcoComponent,
    InfoComponent,
  ],
  providers: [],
})
export class HomeModule { }
