<div class="container intro" *ngIf="aboutUs">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 m-0 p-0">
            <div class="content">
                <div class="title">
                    {{aboutUs.title}}
                </div>
                <div class="description">
                    <p [innerHTML]="aboutUs.description">
                    </p>
                </div>
                <div class="view-more btn">
                    <a href="/about-us">
                        <span>Xem thêm</span>
                        <span class="icon"> <i class="fas fa-chevron-right"></i></span>
                    </a>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 m-0 p-0">
            <img class="w-100 h-100" src="{{aboutUs.image.url}}" alt="" srcset="">
        </div>
    </div>
</div>
