<div class="footer">
  <div class="container-fluid">
    <div class="d-flex justify-content-center row">
      <div class="col-12 col-md-6 column">
        <div class="header">
          <span>CÔNG TY TNHH ECO EXPRESS</span>
        </div>
        <div class="content">
          <div class="text">
            <span class="icon"><i class="fas fa-map-marker-alt"></i></span>
            <span class="text">74/1 Nguyễn Thái Sơn, Phường 3, Quận Gò Vấp, HCM</span>
          </div>
          <div class="text">
            <span class="icon"><i class="fas fa-phone-alt"></i></span>
            <span class="text">+84-0902354886</span>
          </div>
          <div class="text">
            <span class="icon"><i class="fas fa-envelope"></i></span>
            <span class="text">info@ecoexpress.vn</span>
          </div>
          <div class="text">
            <span class="icon"><i class="fas fa-globe"></i></span>
            <span>https://ecoexpress.vn</span>
          </div>
          <div class="text">
            <a href="https://www.facebook.com/pages/category/Product-service/V%E1%BA%ADn-Chuy%E1%BB%83n-Qu%E1%BB%91c-T%E1%BA%BF-EcoExpress-112128604247811/">
              <span class="icon"><i class="fab fa-facebook"></i></span>
              <span class="text">Fanpage</span>
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3 column">
        <div class="header">
          <span>Liên kết nhanh</span>
        </div>
        <div class="content">
          <ul>
            <li><a href="/services/go-to-america">Gửi hàng đi Mỹ</a></li>
            <li><a href="/services/go-to-asia">Gửi hàng đi châu Á</a></li>
            <li><a href="/services/go-to-europe">Gửi hàng đi châu Âu</a></li>
            <li><a href="/services/go-to-other-country">Gửi hàng đi các nước</a></li>
            <li><a href="/services/send-paper-to-other-country">Gửi chứng từ đi nước ngoài</a></li>
          </ul>
        </div>
      </div>
      <div class="col-12 col-md-3 column">
        <div class="header">
          <span>Bản đồ</span>
        </div>
        <div class="content">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3918.9467481886613!2d106.67727537561609!3d10.815387289335817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317528e1057212bf%3A0x15fa4e745ec9a3b2!2zNzQgTmd1eeG7hW4gVGjDoWkgU8ahbiwgUGjGsOG7nW5nIDMsIEfDsiBW4bqlcCwgVGjDoG5oIHBo4buRIEjhu5MgQ2jDrSBNaW5oLCBWaeG7h3QgTmFt!5e0!3m2!1svi!2s!4v1681281410906!5m2!1svi!2s" width="100%" height="250" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>        </div>
      </div>
    </div>
  </div>
  <div class="w-100 copyright">
    Copyright © 2021 ECO EXPRESS. All rights reserved. Thiết kế website: thinhnguyen.dev
  </div>
</div>
