<app-default>
  <div>
    <app-banner *ngIf="banner" [bannerUrl]="banner.thumnail.url" [title]="'Về chúng tôi'"></app-banner>
  </div>
  <div class="row">
    <div class="container pt-4 text-center">
      <p>Với hệ thống hơn 50 đại lý chất lượng và nhiều kinh nghiệm trên khắp thế giới, Chúng tôi đảm bảo cung cấp cho khách hàng dịch vụ Logistics hiệu quả và nhanh chóng cho phép quý công ty tập trung vào năng lực cốt lõi của mình trong khi đó Eco Express sẽ đảm trách việc còn lại.</p>
      <p>Eco Express không ngừng mở rộng và tăng cường hệ thống đại lý để ngày càng hoàn thiện chuỗi cung ứng logistics. Chúng tôi quản lý toàn bộ hoạt động logistics từ khâu mua hàng đến khâu đưa sản phẩm đến tay người tiêu dùng cuối cùng bao gồm nhưng không hạn chế những dịch vụ như: cước vận chuyển quốc tế, thủ tục hải quan, dịch vụ kho bãi…</p>
      <p>Toàn bộ dịch vụ của chúng tôi cung cấp được vận hành theo tiêu chuẩn ISO 9001: 2008</p>
    </div>
  </div>
</app-default>
