<div class="container info">
  <div class="row">
    <div class="col-md-6 co-sm-12 m-0 p-0">
      <!-- <img class="img" src="../assets/imgs/guihangeco.jpg"
        alt="first slide"> -->
        <div class="fb-page" data-href="https://www.facebook.com/ecoexpress.guihangquocte/" data-tabs="timeline" data-width="500" data-height="500" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/ecoexpress.guihangquocte/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/ecoexpress.guihangquocte/">Vận Chuyển Quốc Tế EcoExpress</a></blockquote></div>

    </div>
    <div class="col-md-6 co-sm-12 m-0 news">
      <div class="title">Tin tức mới</div>
      <div class="line"></div>
      <div class="item-list">
        <div class="item" *ngFor="let new of listNews">
          <div><img class="img" [src]="new.thumnail.url" alt=""></div>
          <div class="text"><a [href]="'/news/'+new.id">{{new.title}}</a></div>
        </div>
      </div>
    </div>
  </div>
</div>
