<app-default>
  <div class="container pt-5 pb-5">
    <h1 class="title">Chính sách nhận hàng</h1>
    <p><em><strong>Để đảm bảo quyền lợi và trách nhiệm cả hai bên, Eco Express đưa ra những quy định về chính sách vận chuyển như sau.</strong></em></p>
    <p>Khi sử dụng dịch vụ tại&nbsp;<strong>Eco Express</strong>, điều này có nghĩa khách hàng đồng ý với các quy định và các chính sách tại Eco Express</p>
    <p><strong>Về phía Eco Express</strong></p>
    <ul>
      <li>Đảm bảo thời gian cam kết khi vận chuyển hàng hóa của quý khách</li>
      <li>Kiểm tra tính hợp pháp của hàng hóa nhưng vẫn đảm bảo tính bảo mật, an toàn và toàn vẹn hàng hóa.</li>
      <li>Eco Express từ chối vận chuyển hàng không được lưu thông, hàng dễ cháy nổ, gây nguy hiểm và các loại bưu gửi trái quy định của pháp luật Việt Nam.</li>
      <li>Trong quá trình vận chuyển nếu có hư hỏng, mất mát hay xảy ra sự cố thuộc trách nhiệm LogAsia SCM, chúng tôi sẽ đèn bù theo quy định.</li>
      <li>Giải quyết thắc mắc khiếu nại của khách hàng về hàng hóa.</li>
    </ul>
    <p><strong>Về phía khách hàng</strong></p>
    <ul>
      <li>Cung cấp đầy đủ thông tìn của hàng hóa, mô tả hàng hóa và các giấy tờ cần thiết.</li>
      <li>Thanh toán đầy đủ các loại phí theo thỏa thuận và đồng thuận giữa hai bên.</li>
      <li>Chỉ gửi hàng hợp pháp theo quy định. Không gửi vận chuyển các vật chất gây nổ, vũ khí, chất gây nghiện, ấn phẩm đồi trụy, vật chất gây ô nhiễm môi trường, vật chất có nguồn gốc phi pháp và các loại bưu gửi trái quy định của pháp luật Việt Nam.</li>
    </ul>
  </div>
</app-default>
