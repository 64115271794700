<div class="service">
    <div class="container">
        <div class="row content-server">
            <div class="col-12">
                <div class="title">
                    Dịch vụ nổi bật của chúng tôi
                </div>
                <div class="text-content">
                    Với hệ thống hơn 50 đại lý chất lượng và nhiều kinh nghiệm trên khắp thế giới, Chúng tôi đảm bảo cung cấp cho khách hàng dịch vụ Logistics hiệu quả và nhanh chóng cho phép quý công ty tập trung vào năng lực cốt lõi của mình trong khi đó Ecoexpress sẽ đảm trách việc còn lại.
                </div>
            </div>
            <div class="slider col-12 col-sm-12">
                <carousel *ngIf="slides" [itemsPerSlide]="itemsPerSlide" [singleSlideOffset]="false" [interval]="false"
                    [indicatorsByChunk]="true">
                    <slide *ngFor="let slide of slides" class="col-12 col-md-4">
                        <a [href]="slide.url" class="item">
                            <img [src]="slide.thumnail.url" alt="image slide"
                                style="display: block; width: 100%;">
                            <div class="text">
                                <div class="title">{{slide.title}}</div>
                                <div class="dicription">{{slide.short_description}}</div>
                            </div>
                            <div class="d-flex">
                                <div class="btn">
                                    <span>Tìm Hiểu Thêm</span>
                                    <span class="icon"> <i class="fas fa-chevron-right"></i></span>
                                </div>
                            </div>
                        </a>
                    </slide>
                </carousel>

            </div>
        </div>
    </div>
</div>