import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShareModule } from 'src/app/shared/share.module';
import { FormsModule } from '@angular/forms';
import { SupportComponent } from './pages/support.component';

@NgModule({
  declarations: [SupportComponent],
  imports: [
    CommonModule,
    ShareModule,
    FormsModule
  ]
})
export class SupportModule { }
