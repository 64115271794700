import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-fee',
  templateUrl: './fee.component.html',
  styleUrls: ['./fee.component.scss']
})
export class FeeComponent implements OnInit {

  submitted = false;
  confirm = false;
  feeForm: FormGroup;

  constructor(private fb: FormBuilder) {
    // this.createForm();
  }

  ngOnInit(): void {
  }

  // createForm() {
  //   this.feeForm = this.fb.group({
  //     hoten: ['', Validators.required],
  //     email: ['', Validators.required],
  //     dienThoai: ['', Validators.required],
  //     noiNhanHang: [''],
  //     nơiGuiDen: [''],
  //     kichThuoc: [''],
  //     khoiLuong: [''],
  //     ghiChu: [''],
  //   });
  // }

  // onSubmit() {
  //   this.submitted = true;
  //   if (this.feeForm.invalid) {
  //     return;
  //   }
  //   console.log(this.feeForm.value);
  // }
}
