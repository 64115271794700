import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShareModule } from 'src/app/shared/share.module';
import { OurServicesRoutingModule } from './our-services-routing.module';
import { GoToOtherCountryComponent } from './pages/goToOtherCountry/goToOtherCountry.component';
import { GotoAsiaComponent } from './pages/gotoAsia/gotoAsia.component';
import { GoToAmericaComponent } from './pages/goToAmerica/goToAmerica.component';
import { GoToEuropeComponent } from './pages/goToEurope/goToEurope.component';
import { SendPaperToOtherCountryComponent } from './pages/sendPaperToOtherCountry/sendPaperToOtherCountry.component';
import { OtherServicesComponent } from './pages/otherServices/otherServices.component';

@NgModule({
  declarations: [ GoToOtherCountryComponent, GotoAsiaComponent, GoToAmericaComponent, GoToEuropeComponent, SendPaperToOtherCountryComponent, OtherServicesComponent],
  imports: [
    CommonModule,
    ShareModule,
    OurServicesRoutingModule,
  ]
})
export class OurServicesModule { }
