<div class="container-fluid page-404">
    <div class="mainbox">
        <div class="err">4</div>
        <i class="far fa-question-circle fa-spin"></i>
        <div class="err2">4</div>
        <div class="msg">
            page not found
        </div>  
    </div>

</div>