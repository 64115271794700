import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShareModule } from 'src/app/shared/share.module';
import { PolicyRoutingModule } from './policy-routing.module';
import {DeliveryComponent} from './pages/delivery/delivery.component';
import {PaymentComponent} from './pages/payment/payment.component';
import {CompensationComponent} from './pages/compensation/compensation.component';
import {ReceiveComponent} from './pages/receive/receive.component';
import { OptionPriceComponent } from './pages/option-price/option-price.component';

@NgModule({
  declarations: [
    DeliveryComponent,
    PaymentComponent,
    CompensationComponent,
    ReceiveComponent,
    OptionPriceComponent
  ],
  imports: [
    CommonModule,
    ShareModule,
    PolicyRoutingModule,
  ]
})
export class PolicyModule { }
