import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  submitted = false;
  confirm = false;
  supportForm: FormGroup;

  constructor(private fb: FormBuilder) {
    // this.createForm();
  }

  ngOnInit(): void {
  }

  // createForm() {
  //   this.supportForm = this.fb.group({
  //     name: ['', Validators.required],
  //     email: ['', Validators.required],
  //     title: [''],
  //     message: [''],
  //   });
  // }

  // onSubmit() {
  //   this.submitted = true;
  //   if (this.supportForm.invalid) {
  //     return;
  //   }
  //   console.log(this.supportForm.value);
  // }
}
