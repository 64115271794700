import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { FeeComponent } from './modules/fee/pages/fee.component';
import { Page404Component } from './modules/page404/page404/page404.component';
import { SupportComponent } from './modules/support/pages/support.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: "full",
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'news',
    loadChildren: () =>
      import('./modules/news/news.module').then((m) => m.NewsModule),
  },
  {
    path: 'services',
    loadChildren: () =>
      import('./modules/our-services/our-services.module').then((m) => m.OurServicesModule),
  },
  {
    path: 'about-us',
    loadChildren: () =>
      import('./modules/about-us/about.module').then((m) => m.AboutModule),
  },
  {
    path: 'policy',
    loadChildren: () =>
      import('./modules/policy/policy.module').then((m) => m.PolicyModule),
  },
  {
    path: 'support',
    component: SupportComponent
  }, {
    path: 'fee',
    component: FeeComponent
  },
  {
    path: '**',
    component: Page404Component,
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
