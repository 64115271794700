import { Component, OnInit } from '@angular/core';
import { FacebookService, InitParams } from 'ngx-facebook';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  title: string = 'HÃY LIÊN HỆ NGAY VỚI CHÚNG TÔI​';
  text: string =
    'Dù đó là một gói hàng, tài liệu hay pallet, chúng tôi đều đảm bảo giao nhận đúng giờ. Với công ty chuyển phát nhanh Ecoexpress việc gửi lô hàng của quý khách chưa bao giờ dễ dàng hơn thế.';

  zaloId: string = "579745863508352884";

  constructor(private fb: FacebookService) {
    const initParams: InitParams = {
      xfbml: true, version: 'v3.2'
    };
    fb.init(initParams);
  }

  ngOnInit(): void {
  }

}
