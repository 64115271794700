import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiServer } from 'src/app/shared/constants/host';

@Injectable({
  providedIn: 'root'
})


export class HomePageServiceService {

  serverUrl = apiServer;

  constructor(private http: HttpClient
  ) { }

  getBanner = () => {
    return this.http.get(`${this.serverUrl}/banners`);
  }

  getAboutUs = () => {
    return this.http.get(`${this.serverUrl}/about-uses`);
  }

  getShortServices = () => {
  return this.http.get(`${this.serverUrl}/trend-services`);
  }
  getSlider = () => {
    return this.http.get(`${this.serverUrl}/list-services`);
  }
  
  getNews = () => {
    return this.http.get(`${this.serverUrl}/events`);
  }
}
