import { Component, OnInit } from '@angular/core';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { apiServer } from 'src/app/shared/constants/host';
import { IBanner } from 'src/app/shared/models/iBanner';
import { HomePageServiceService } from '../../home-page-service.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 2500, noPause: true, showIndicators: true } }
  ]
})
export class HomeComponent implements OnInit {

  banner = [
    {
      url: '../assets/imgs/banner.jpg',
    },
    {
      url: '../assets/imgs/1.jpg',
    },
    {
      url: '../assets/imgs/2.jpg',
    }
  ];
  serverUrl = apiServer;

  constructor(private homePageServiceService: HomePageServiceService) { }

  ngOnInit(): void {
  //   this.homePageServiceService.getBanner().subscribe(
  //     res => {
  //       if (res && res[0])
  //         this.banner = res;
  //     },
  //     err => {
  //       console.log(err);
  //     });
  }
}
