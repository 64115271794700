import { Component, Input, OnInit } from '@angular/core';
import { apiServer } from 'src/app/shared/constants/host';
import { IBanner } from 'src/app/shared/models/iBanner';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  @Input() bigSize: boolean = false;
  @Input() bannerUrl: string = "";
  @Input() title: string = "";

  banner: IBanner;
  serverUrl = apiServer;

  constructor() { }

  ngOnInit(): void {
  }
}
