import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  @ViewChild('curtain') divCurtain: ElementRef;

  setHeight: boolean = false;

  constructor() { }

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    if (window.pageYOffset > 3) {
      this.setHeight = true;
    } else {
      this.setHeight = false;
    }
  }

  ngOnInit(): void {
  }

}
