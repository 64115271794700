<app-default>
  <!-- <app-banner *ngIf="banner" [bannerUrl]="banner.thumnail.url" [bigSize]="true"
    [title]="'Chào mừng bạn đến với EcoExpress'">
  </app-banner> -->
  <carousel class="slider">
    <slide *ngFor="let item of banner">
      <img [src]="item.url" alt="first slide" style="display: block; width: 100%;height: 500px;">
      <div class="text-middle ">
        <span>Chào mừng bạn đến với EcoExpress</span>
      </div>
    </slide>
  </carousel>
  <app-intro></app-intro>
  <app-service-eco></app-service-eco>
  <app-info></app-info>
</app-default>
