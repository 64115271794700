<app-default>
    <div *ngIf="banner" class="banner-container container-fluid banner1">
        <!-- <img class="img" src="{{serverUrl+banner.thumnail.url}}" alt="first slide"> -->
        <img class="img" src="../../../../../assets/imgs/container.jpg" alt="first slide">
        <div class="img-background"></div>
        <div class="banner-title container">
            <div class="title1">Dịch vụ</div>
            <div class="title2">Vận chuyển – gửi hàng đi châu á
            </div>
            <div class="decription">Vận chuyển hàng đi châu á uy tín,
            </div>
            <div class="decription">Chưa bao giờ gửi hàng đi các nước châu á tại VIỆT NAM lại dễ dàng đến thế.
            </div>
            <div class="button-group">
                <div class="button"><a class="phone" href="tel:+84902354886" class="phone"><i
                            class="fas fa-phone-alt"></i> gọi báo giá ngay</a></div>
            </div>
        </div>
    </div>
    <div class="our-services-container container-fluid m-0 p-0">
        <div class="first-row container-fluid m-0 p-0 col-12">
            <div class="container row-content row">
                <div class="col-md-4 col-sm-12">
                    <div class="icon"><i class="fas fa-plane-departure"></i></div>
                    <div class="title-1">Vận Chuyển Hàng Không </div>
                    <div class="decription-1">Với thời gian vận chuyển nhanh từ 2-3 ngày. Thủ tục đơn giản, dễ dàng và
                        nhanh chóng. </div>
                </div>
                <div class="col-md-4 col-sm-12">
                    <div class="icon"><i class="fas fa-ship"></i></div>
                    <div class="title-1">Vận Chuyển Đường Biển </div>
                    <div class="decription-1">Cung cấp giá cước tiết kiệm, phù hợp với các mặt hàng nặng, số lượng lớn.
                        Thời gian vận chuyển từ 30-45 ngày. </div>
                </div>
                <div class="col-md-4 col-sm-12">
                    <div class="icon"><i class="fas fa-tasks"></i></div>
                    <div class="title-1">An Toàn Hàng Hóa </div>
                    <div class="decription-1">Hàng hoá được bảo hiểm 100% (*). Các mặt hàng khó vận chuyển sẽ được

                    </div>
                </div>
            </div>
        </div>
        <div class="intro-row container-fluid m-0 p-0 col-12">
            <div class="filter">
                <div class="container row-content row">
                    <div class="col-sm-12 col-md-8">
                        <div class="decription-2">Giới thiệu </div>
                        <div class="title-1">Dịch vụ gửi hàng đi châu á (Hàn Quốc, Trung Quốc, Nhật Bản, Đài Loan,..)
                        </div>
                        <div class="line"></div>
                        <div class="decription-1">
                            <div class="item"> <i class="fas fa-bahai"></i> ECO EXPRESS là đại lý chính thức của
                                các hãng vận chuyển lớn nên chúng tôi đảm bảo rằng khách hàng luôn có cước
                                phí Ship hàng đi châu á tiết kiệm nhất
                            </div>
                            <div class="item"> <i class="fas fa-bahai"></i> Bởi mỗi hãng chuyển phát nhanh đều có
                                những thế mạnh riêng phụ thuộc vào từng lô hàng, trọng lượng và địa chỉ gửi
                                đến.


                            </div>
                            <div class="item"> <i class="fas fa-bahai"></i> Với đội ngũ nhân viên làm việc chuyên
                                nghiệp, am hiểu về chuyên môn trong lĩnh vực vận chuyển.


                            </div>
                            <div class="item"> <i class="fas fa-bahai"></i> Chúng tôi sẽ chia sẻ cho khách hàng
                                những kinh nghiệm bổ ích, giúp cho việc Ship hàng đi châu á nhanh chóng, an toàn
                                và cước phí vận chuyển tiết kiệm nhất.


                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 d-flex img-container">
                        <img class="img" src="../../../../../assets/imgs/img-4.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="second-row container-fluid m-0 p-0 col-12">
            <div class="container row-content row">
                <div class="col-12">
                    <div class="decription-2">Vấn đề Khó khăn </div>
                    <div class="title-1"><span style="color: red;">90% </span>khách hàng gửi hàng đi châu á gặp phải ?
                    </div>
                    <div class="line"></div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="decription-1">
                        <div class="item"> <i class="far fa-times-circle"></i> Tự mang hàng hóa cần gửi ra bưu điện và
                            tự làm thủ tục

                        </div>
                        <div class="item"><i class="far fa-times-circle"></i> Thời gian vận chuyển lâu nhưng giá cước
                            không rẻ

                        </div>
                        <div class="item"> <i class="far fa-times-circle"></i> Không được tư vấn phương thức vận chuyển
                            tiết kiệm nhất

                        </div>
                        <div class="item"> <i class="far fa-times-circle"></i> Tự chủ động kiểm tra tình trạng của đơn
                            hàng

                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="picture w-100">
                        <img class="w-100 h-100" src="../../../../../assets/imgs/banner-2.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="third-row container-fluid m-0 p-0 col-12">
            <div class="filter">
                <div class="container row-content row">
                    <div class="col-12">
                        <div class="decription-2">Trải nghiệm ngay </div>
                        <div class="title-1">Dịch vụ chuyển hàng đi châu á chuyên nghiệp</div>
                        <div class="line"></div>
                        <div class="decription-1">
                            <div class="item"> <i class="fas fa-award"></i> Tư vấn tận tâm: Tư vấn kỹ lưỡng hình thức
                                vận chuyển giá tốt nhất.
                            </div>
                            <div class="item"> <i class="fas fa-award"></i> Chất lượng nghiệp vụ: Đội ngũ nhân viên
                                chuyên nghiệp trình độ chuyên môn cao.

                            </div>
                            <div class="item"> <i class="fas fa-award"></i> Báo giá nhanh: Báo giá vận chuyển chỉ sau
                                2-5 phút, trọn gói chi phí A-Z.

                            </div>
                            <div class="item"> <i class="fas fa-award"></i> Thanh toán dễ dàng: Thanh toán qua chuyển
                                khoản, COD và tại văn phòng.

                            </div>
                            <div class="item"> <i class="fas fa-award"></i> Vận chuyển nhanh chóng: Tổng hành trình vận
                                chuyển chỉ từ 2-5 ngày, hàng đi trong ngày.

                            </div>
                            <div class="item"> <i class="fas fa-award"></i> Bảo hiểm hàng hóa: Tất cả hàng hóa đều được
                                bảo hiểm, đem lại sự an tâm cho khách hàng.

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="four-row container-fluid m-0 p-0 col-12">
            <div class="container row-content row">
                <div class="col-12">
                    <div class="decription-2">Chúng tôi luôn sẵn sàng để giúp đỡ bạn
                    </div>
                    <div class="title-1">Đừng lo lắng về bất kỳ vấn đề vận chuyển của bạn. Hãy để chúng tôi hỗ trợ bạn
                        tốt nhất.

                    </div>
                    <div><a href="tel:+84902354886" class="button phone"><i class="fas fa-phone-alt"></i> Gọi ngay:
                            0902354886</a></div>
                </div>
            </div>
        </div>
        <div class="five-row container-fluid m-0 p-0 col-12">
            <div class="filter">
                <div class="container row-content row">
                    <div class="col-12 col-md-4">
                        <div class="img"><img src="../../../../../assets/imgs/img-1.png" alt="">
                        </div>
                    </div>
                    <div class="col-12 col-md-8">
                        <div class="decription-2">Trải nghiệm ngay
                        </div>
                        <div class="title-1">ECO EXPRESS vận chuyển rất nhiều mặt hàng đi châu á như:
                        </div>
                        <div class="line"></div>
                        <div class="decription-1">
                            <ul>
                                <li class="item">Hàng cồng kềnh: gửi nệm đi châu á, bàn ghế, tủ giường, nội thất gỗ đi
                                    châu á….
                                </li>
                                <li class="item">Hàng dễ vỡ đi châu á : chén dĩa sứ, thủy tinh, tượng gốm sứ, tượng gỗ,
                                    tranh thêu, tranh đá,tranh vẽ…..
                                </li>
                                <li class="item">Hàng máy móc đi châu á : Linh kiện điện tử,máy xay thịt, máy ép nước
                                    mía,
                                    các loại máy tự chế, máy móc khác.
                                </li>
                                <li class="item">Quần áo đi châu á với giá cước cực rẻ : gửi quần áo, giày dép, đồ dùng,
                                    dụng cụ gia đình…..
                                </li>
                                <li class="item">Hàng thực phẩm đi châu á: cá khô, gạo, bánh, kẹo,…
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="six-row container-fluid m-0 p-0 col-12">
            <div class="container row-content row">
                <div class="col-12 col-md-8">
                    <div class="decription-2">Đảm bảo
                    </div>
                    <div class="title-1">ECO EXPRESS đảm bảo:
                    </div>
                    <div class="line"></div>
                    <div class="decription-1">
                        <ul>
                            <li class="item">Dịch vụ gửi hàng: An toàn – nhanh chóng – tiết kiệm – hiệu quả.
                            </li>
                            <li class="item">Cấp miễn phí cho quý khách thùng carton đóng hàng và hỗ trợ đóng hàng
                                để bảo đảm đúng tiêu chuẩn vận chuyển đường hàng không).
                            </li>
                            <li class="item">Giúp kiểm tra và theo dõi lô hàng của quý khách trong suốt hành trình
                                gửi hàng cho tới khi hàng đến nơi người nhận.
                            </li>
                        </ul>
                    </div>
                    <div class="line"></div>
                    <div class="title-1">Thời gian gửi hàng:
                    </div>
                    <div class="decription-1">
                        <div style="margin-bottom: 20px;">Thời gian vận chuyển hàng đi châu á tùy thuộc vào gói dịch vụ:
                        </div>
                        <ul>
                            <li class="item">Chuyển phát nhanh đi châu á: 2-3 ngày làm việc.
                            </li>
                            <li class="item">Chuyển phát tiết kiệm đi châu á: 5-7 ngày làm việc.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="img"><img src="../../../../../assets/imgs/img-3.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="seven-row container-fluid m-0 p-0 col-12">
            <div class="filter">
                <div class="container row-content row">
                    <div class="col-12">
                        <div class="title-1">Tại sao chọn gửi hàng đi châu á bởi ECO EXPRESS?
                        </div>
                        <div class="line"></div>
                    </div>
                    <div class="col-md-4 col-sm-4">
                        <div class="card-item">
                            <div class="icon"><i class="fas fa-american-sign-language-interpreting"></i></div>
                            <div class="decription-1">GIAO NHẬN TẬN NHÀ </div>
                            <div class="decription-2">Miễn phí nhận hàng đóng gói tận nhà, giao hàng tận tay người nhận.
                                Đảm bảo tiện lợi, nhanh chóng cho khách hàng.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4">
                        <div class="card-item">
                            <div class="icon"><i class="fas fa-truck"></i></div>
                            <div class="decription-1">VẬN CHUYỂN NHANH CHÓNG
                            </div>
                            <div class="decription-2">Đảm bảo hàng hòa vận chuyển trong ngày với thời gian ngắn nhất. Từ
                                2-3 ngày đối với đường hàng không.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4">
                        <div class="card-item">
                            <div class="icon"><i class="fas fa-credit-card"></i></div>
                            <div class="decription-1">THANH TOÁN DỄ DÀNG
                            </div>
                            <div class="decription-2">Bạn có thể thanh toán trực tiếp tại văn phòng của ECO EXPRESS, qua
                                Internet Banking hoặc thanh toán qua hình thức COD.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4">
                        <div class="card-item">
                            <div class="icon"><i class="fab fa-bandcamp"></i></div>
                            <div class="decription-1">BÁO GIÁ NHANH CHÓNG
                            </div>
                            <div class="decription-2">Báo giá nhanh chóng sau 3-5 phút, cung cấp đầy đủ thông tin chi
                                tiết về phương thức vận chuyển phù hợp.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4">
                        <div class="card-item">
                            <div class="icon"><i class="fas fa-snowflake"></i></div>
                            <div class="decription-1">BẢO ĐẢM AN TOÀN
                            </div>
                            <div class="decription-2">Cam kết hàng hóa được đóng gói cẩn thận, chuyên nghiệp. Hàng hóa
                                được giao đến tay người nhận nguyên vện an toàn.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4">
                        <div class="card-item">
                            <div class="icon"><i class="fas fa-gem"></i></div>
                            <div class="decription-1">BẢO HIỂM HÀNG HÓA
                            </div>
                            <div class="decription-2">Tất cả hàng hóa được vận chuyển bởi ECO EXPRESS đều được bảo hiểm.
                                Đảm bảo dịch vụ hoàn hảo và tiện lợi
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="eight-row container-fluid m-0 p-0 col-12">
            <div class="container row-content row">
                <div class="col-12 title-2"> Quy trình </div>
                <div class="col-12 title-3"><span style="color: #e14d43;font-size: 30px;"> 3</span> bước gửi hàng đi
                    châu á
                    dễ dàng: </div>
                <div class="col-md-4 col-sm-12">
                    <div class="icon"><i class="fas fa-user"></i></div>
                    <div class="title-1">Bước 1</div>
                    <div class="decription-1">Tiếp nhận thông tin hàng hóa, cân nặng từ khách hàng sau đó báo giá.
                    </div>
                </div>
                <div class="col-md-4 col-sm-12">
                    <div class="icon"><i class="fas fa-truck"></i></div>
                    <div class="title-1">Bước 2</div>
                    <div class="decription-1">Nhân viên đến tận nhà đóng gói và khai báo hàng hóa miễn phí cho khách
                        hàng.
                    </div>
                </div>
                <div class="col-md-4 col-sm-12">
                    <div class="icon"><i class="fas fa-person-booth"></i></div>
                    <div class="title-1">Bước 3</div>
                    <div class="decription-1">Hàng hòa được vận chuyển và giao đến tận tay người nhận. Kết thúc hành
                        trình.
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="last-row container-fluid m-0 p-0 col-12">
            <div class="filter">
                <div class="container row-content row">
                    <div class="col-12">
                        <div class="decription-2">Bạn cần gửi hàng đi châu á </div>
                        <div class="title-1">Chúng tôi luôn sẵn sàng để giúp đỡ bạn </div>
                        <div><a href="#" class="button"><i class="fas fa-phone-alt"></i> Yêu cầu báo giá</a></div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</app-default>