import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DeliveryComponent} from './pages/delivery/delivery.component';
import {PaymentComponent} from './pages/payment/payment.component';
import {CompensationComponent} from './pages/compensation/compensation.component';
import {ReceiveComponent} from './pages/receive/receive.component';
import {OptionPriceComponent} from './pages/option-price/option-price.component';

const routes: Routes = [
    {
        path: 'delivery',
        component: DeliveryComponent,
    },
    {
      path: 'payment',
      component: PaymentComponent,
    },
    {
      path: 'compensation',
      component: CompensationComponent,
    },
    {
      path: 'receive',
      component: ReceiveComponent,
    },
    {
      path: 'option-price',
      component: OptionPriceComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PolicyRoutingModule { }
