import { Component, OnInit } from '@angular/core';
import { apiServer } from 'src/app/shared/constants/host';
import { HomePageServiceService } from '../../home-page-service.service';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {

  aboutUs = {
    "id": 1,
    "title": "Về chúng tôi.",
    "description": "Ecoexpress tự hào có tốc độ phát triển dẫn đầu trong ngành vận tải giao nhận, chuyển phát hàng hóa.<br/><p></p>\n\nVới đội ngũ nhân viện giàu kinh nghiệm và hết lòng với nghề, chúng tôi tự tin đưa đến cho quý khách hàng những dịch vụ và giải pháp vận chuyển tốt nhất cũng như bảo vệ lợi ích của khách hàng.<br/><p></p>\n\nPhục vụ khách hàng bởi sự tận tâm<br/><p></p>\n\nMong muốn trở thành đối tác tin cậy của khách hàng, cung cấp những dịch vụ, giải pháp tốt nhất với chi phí thấp nhất",
    "link": "12 3123 123 ",
    "published_at": "2021-02-04T12:26:26.000Z",
    "created_at": "2021-02-04T12:21:25.000Z",
    "updated_at": "2021-04-08T15:01:11.000Z",
    "image": {
      "id": 18,
      "name": "a1f8b679bd464f181657.jpg",
      "alternativeText": "",
      "caption": "",
      "width": 890,
      "height": 752,
      "hash": "a1f8b679bd464f181657_08599cf067",
      "ext": ".jpg",
      "mime": "image/jpeg",
      "size": 151.52,
      "url": '../assets/imgs/services.jpg',
      "previewUrl": null,
      "provider": "local",
      "provider_metadata": null,
      "created_at": "2021-04-08T14:55:43.000Z",
      "updated_at": "2021-04-08T14:55:43.000Z"
    }
  }
  serverUrl = apiServer;

  constructor(private homePageServiceService: HomePageServiceService) { }

  ngOnInit(): void {
    // this.homePageServiceService.getAboutUs().subscribe(
    //   res => {
    //     if (res && res[0])
    //       this.aboutUs = res[0];
    //   },
    //   err => {
    //     console.log(err);
    //   });
  }

}
