import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DetailsComponent } from './components/details/details.component';
import { NewsComponent } from './pages/news.component';

const routes: Routes = [
    {
        path: '',
        component: NewsComponent,
    }, {
        path: ':id',
        component: DetailsComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class NewsRoutingModule { }
