import { Component, OnInit } from '@angular/core';
import {NewsService} from '../../../news/news.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor(private newsService: NewsService) { }
  banner;
  ngOnInit(): void {
    this.newsService.getBanner().subscribe(
      res => {
        if (res && res[0])
          this.banner = res[0];
      },
      err => {
        console.log(err);
      });
  }

}
