<app-default>
  <div class="banner-container container-fluid banner1">
    <img class="img" src="../../../../../assets/imgs/banner-city.jpg" alt="first slide">
    <div class="img-background"></div>
    <div class="banner-title container" *ngIf="news">
      <div class="title1">Tin Tức</div>
      <div class="decription">{{news.title}}</div>
      <div class="line"></div>
    </div>
  </div>
  <div class="news-details-container container">
    <div class="row">
      <div class="col-md-9 row col-sm-12">
        <div class="left-side" *ngIf="news" [innerHTML]="news.description">

        </div>
      </div>
      <div class="col-md-3 right-side col-sm-12">
        <div class="about-us">
          <div class="header-content">eco express</div>
          <div class="line"></div>
          <div class="decription"> Công ty TNHH ECO EXPRESS là một trong những công ty hàng đầu Việt Nam trong lĩnh vực
            Forwarding và Logistics
          </div>
          <div class="more-details"><a href="/about-us">Xem thêm ></a></div>
        </div>
        <div class="our-services">
          <div class="header-content">DỊCH VỤ</div>
          <div class="line"></div>
          <div class="content">
            <div> <a href="/services/go-to-america">Gửi hàng đi Mỹ</a></div>
            <div> <a href="/services/go-to-asia">Gửi hàng đi châu Á</a></div>
            <div> <a href="/services/go-to-europe">Gửi hàng đi châu Âu</a></div>
            <div> <a href="/services/go-to-other-country">Gửi hàng đi các nước</a></div>
            <div> <a href="/services/send-paper-to-other-country">Gửi chứng từ đi nước ngoài</a></div>
          </div>
        </div>
        <div class="news-updated">
          <div class="header-content">TIN TỨC MỚI</div>
          <div class="line"></div>
          <div class="item" *ngFor="let item of listNews | slice:0:4;">
            <div class="calendar">
              <div class="days">{{convertDays(item.published_at)}}</div>
              <div class="date">{{convertMonth(item.published_at)}}</div>
            </div>
            <div class="link">
              <a [href]="'/news/'+item.id">{{item.title}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-default>